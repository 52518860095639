import Link from "next/link";
import Image from "next/image";
import { Center, Heading, Stack, Text } from "@chakra-ui/react";

import PageContainer from "../../common/PageContainer/PageContainer";
import Button from "../../common/Button/Button";
import { topPageContent } from "./topPageContent";

const TopPageMobile = () => {
  return (
    <PageContainer>
      <Center>
        <Stack gap={10}>
          <Stack gap={2} alignItems={"center"}>
            <Image
              src={"/images/logo@3.png"}
              alt={"Askq"}
              width={"200px"}
              height={"100px"}
              objectFit={"contain"}
            />
            <Text fontWeight={"bold"}> {topPageContent.description} </Text>
          </Stack>
          <Stack p={2} alignItems={"center"}>
            <Heading> {topPageContent.text1.header}</Heading>
            {topPageContent.text1.body.map((b) => (
              <Text fontWeight={"bold"} key={b}>
                {b}
              </Text>
            ))}
            <Image
              src={topPageContent.text1.image}
              alt={topPageContent.text1.imageAlt}
              width={"300px"}
              height={"300px"}
              objectFit={"contain"}
            />
          </Stack>
          <Stack p={2} alignItems={"center"}>
            <Heading> {topPageContent.text2.header}</Heading>
            {topPageContent.text2.body.map((b) => (
              <Text fontWeight={"bold"} key={b}>
                {b}
              </Text>
            ))}
            <Image
              src={topPageContent.text2.image}
              alt={topPageContent.text2.imageAlt}
              width={"300px"}
              height={"300px"}
              objectFit={"contain"}
            />
          </Stack>
          <Stack p={2} alignItems={"center"}>
            <Heading> {topPageContent.text3.header}</Heading>
            {topPageContent.text2.body.map((b) => (
              <Text fontWeight={"bold"} key={b}>
                {b}
              </Text>
            ))}
            <Image
              src={topPageContent.text3.image}
              alt={topPageContent.text3.imageAlt}
              width={"300px"}
              height={"300px"}
              objectFit={"contain"}
            />
          </Stack>
          <Stack alignItems={"center"}>
            <Link href="/login" style={{ width: "100%" }}>
              <a>
                <Button mode="primary">アンケートを作る</Button>
              </a>
            </Link>
            <Link href="/login" style={{ width: "100%" }}>
              <a>
                <Button mode="normal">サンプルアンケートを見てみる</Button>
              </a>
            </Link>
          </Stack>
        </Stack>
      </Center>
    </PageContainer>
  );
};

export default TopPageMobile;
