export const topPageContent = {
  serviceName: "Askq",
  description: "誰でも使えるアンケート作成クラウドサービス",
  text1: {
    header: "ユーザ調査を素早く正確に",
    body: [
      "利用開始から5分でアンケートを作成、公開",
      "マーケティングや市場調査に",
      "回答はグラフや表でリアルタイムに可視化されます",
    ],
    image: "/images/predictive_analytics.svg",
    imageAlt: "マーケティング",
  },
  text2: {
    header: "簡単なアンケートにも",
    body: [
      "プロユースはもちろん、個人でも利用できます",
      "SNSでのアンケート調査、仲間での投票にも",
    ],
    image: "/images/having_fun.svg",
    imageAlt: "アンケートにも",
  },
  text3: {
    header: "データは全てクラウドに",
    body: [
      "回答データはクラウド上に安全に保管されます",
      "CSVでのダウンロードにも対応。お手元のツールで分析もできます",
    ],
    image: "/images/cloud_docs.svg",
    imageAlt: "クラウド",
  },
};
