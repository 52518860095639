import React from "react";
import TopPagePC from "./TopPagePC";
import TopPageMobile from "./TopPageMobile";
import { useMobile } from "../../../hooks/useMobile";

const TopPage = () => {
  const isMobile = useMobile();
  if (isMobile) {
    return <TopPageMobile />;
  } else {
    return <TopPagePC />;
  }
};

export default TopPage;
