import React, { memo } from "react";
import { Button as CButton, ButtonProps } from "@chakra-ui/react";

type Props = {
  mode?: "primary" | "normal" | "danger";
  testID?: string;
} & ButtonProps;

const colors = {
  primary: "blue",
  normal: "gray",
  danger: "red",
};

const Button = memo(function Button({
  mode = "normal",
  children,
  ...props
}: Props) {
  const styles =
    mode === "primary"
      ? {
          bg: "#67A5DA",
          color: "white",
          _hover: { bg: "#67A5DA", opacity: 0.8 },
        }
      : undefined;
  return (
    <CButton colorScheme={colors[mode]} {...props} {...styles}>
      {children}
    </CButton>
  );
});
export default Button;
