import Head from "next/head";

const title = "Askq";
const description = "誰でも使えるアンケート作成クラウドサービス";

const AppHead = () => {
  return (
    <Head>
      <title key="title"> {title} </title>
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1.0"
      />
      <meta key="description" name="description" content={description} />
      <meta key="og:site_name" property="og:site_name" content="Askq" />
      <meta key="og:title" property="og:title" content={title} />
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:locale" property="og:locale" content="ja_JP" />
      <meta
        key="og:image"
        property="og:image"
        content={`https://firebasestorage.googleapis.com/v0/b/${process.env.NEXT_PUBLIC_FB_PROJECT_ID}.appspot.com/o/ogp%2Fogp_main.png?alt=media`}
      />
      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter:site" name="twitter:site" content="@askq_jp" />
    </Head>
  );
};

export default AppHead;
