import Head from "next/head";

import Top from "../components/pages/TopPage/TopPage";
import AppHead from "../components/common/AppHead/AppHead";

const TopPage = () => {
  return (
    <>
      <AppHead />
      <Top />
    </>
  );
};

export default TopPage;
