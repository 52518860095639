import Link from "next/link";
import Image from "next/image";
import { Center, Heading, HStack, Stack, Text } from "@chakra-ui/react";

import PageContainer from "../../common/PageContainer/PageContainer";
import Button from "../../common/Button/Button";
import { topPageContent } from "./topPageContent";
import Logo from "../../../assets/images/logo@3.png";

const TopPagePC = () => {
  return (
    <PageContainer>
      <Center>
        <Stack>
          <HStack gap={6} alignItems={"center"} justifyContent={"center"}>
            <Image
              src={Logo}
              alt={"Askq"}
              width={"200px"}
              height={"100px"}
              objectFit={"contain"}
            />
            <Text fontWeight={"bold"}> {topPageContent.description} </Text>
          </HStack>
          <HStack gap={4}>
            <Stack>
              <Heading> {topPageContent.text1.header}</Heading>
              {topPageContent.text1.body.map((b) => (
                <Text fontWeight={"bold"} key={b}>
                  {b}
                </Text>
              ))}
            </Stack>
            <Image
              src={topPageContent.text1.image}
              alt={topPageContent.text1.imageAlt}
              width={"300px"}
              height={"300px"}
              objectFit={"contain"}
            />
          </HStack>
          <HStack gap={4}>
            <Image
              src={topPageContent.text2.image}
              alt={topPageContent.text2.imageAlt}
              width={"300px"}
              height={"300px"}
              objectFit={"contain"}
            />
            <Stack>
              <Heading> {topPageContent.text2.header} </Heading>
              {topPageContent.text2.body.map((b) => (
                <Text fontWeight={"bold"} key={b}>
                  {b}
                </Text>
              ))}
            </Stack>
          </HStack>
          <HStack gap={4}>
            <Stack>
              <Heading> {topPageContent.text3.header} </Heading>
              {topPageContent.text3.body.map((b) => (
                <Text fontWeight={"bold"} key={b}>
                  {b}
                </Text>
              ))}
            </Stack>
            <Image
              src={topPageContent.text3.image}
              alt={topPageContent.text3.imageAlt}
              width={"300px"}
              height={"300px"}
              objectFit={"contain"}
            />
          </HStack>
          <HStack justifyContent={"center"}>
            <Link href="/login">
              <a>
                <Button mode="primary">アンケートを作る</Button>
              </a>
            </Link>
            <Link href="/login">
              <a>
                <Button mode="normal">サンプルアンケートを見てみる</Button>
              </a>
            </Link>
          </HStack>
        </Stack>
      </Center>
    </PageContainer>
  );
};

export default TopPagePC;
