import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

type Props = BoxProps;

const PageContainer: React.FC<Props> = ({ children }) => {
  return (
    <Box p={{ base: "2", md: "10" }} mb={6}>
      {children}
    </Box>
  );
};

export default PageContainer;
